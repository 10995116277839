import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Image, Icon, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { TiWavesOutline } from "react-icons/ti";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				OceanWave Surf Academy
			</title>
			<meta name={"description"} content={"Akademimiz sizi okyanusun ritmini kucaklamaya ve sörfün heyecanını keşfetmeye davet ediyor."} />
			<meta property={"og:title"} content={"OceanWave Surf Academy"} />
			<meta property={"og:description"} content={"Akademimiz sizi okyanusun ritmini kucaklamaya ve sörfün heyecanını keşfetmeye davet ediyor."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text1" />
			<Override slot="box5" />
			<Override slot="box4" />
			<Override slot="link2" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
		</Components.Header2>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box margin="-16px -16px -16px -16px" padding="16px 16px 16px 16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box
						background="url(https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-1.jpg?v=2024-06-06T12:06:02.413Z) 50% 15%/cover"
						padding="0px 0px 672px 0px"
						margin="0px -112px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
					/>
				</Box>
				<Box width="50%" display="flex" padding="16px 16px 0px 16px" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="lighten"
						background="--color-primary"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-margin="0px 0px 0px 0px"
						lg-width="100%"
						sm-padding="64px 32px 64px 32px"
					>
						<Text
							as="h1"
							margin="auto 0 auto 0"
							font="--headline1"
							lg-font="--headline2"
							text-transform="uppercase"
							align-self="center"
						>
							Macera Dalgasını Yakalayın
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Denizin macera ruhuyla buluştuğu OceanWave Surf Academy'ye hoş geldiniz!
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Akademimiz sizi okyanusun ritmini kucaklamaya ve sörfün heyecanını keşfetmeye davet ediyor. İster köpüğü ilk kez hisseden bir sörfçü olun, ister becerilerini geliştirmek isteyen deneyimli bir sörfçü olun, kapılarımız tüm dalga tutkunlarına açıktır.
			</Text>
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Bizimle sörf yapmak, kendini mükemmelliğe ve eğlenceye adamış bir topluluğa katılmak anlamına gelir.
			</Text>
			<Box
				display="flex"
				margin="40px 0 20px 0"
				justify-content="center"
				sm-flex-direction="column"
				align-self="center"
				align-content="center"
				align-items="center"
			>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12:06:02.480Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-5.jpg?v=2024-06-06T12%3A06%3A02.480Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="center">
						<Icon
							category="ti"
							icon={TiWavesOutline}
							margin="0px 0px 16px 0px"
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Uzman Eğitmenler
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Ekibimiz sadece spora tutkuyla değil aynı zamanda öğretme konusunda da tutkulu olan tecrübeli sörfçülerden oluşmaktadır.
						</Text>
					</Box>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12:06:02.435Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box flex-direction="column" display="flex" align-items="center">
						<Icon
							category="ti"
							icon={TiWavesOutline}
							margin="0px 0px 16px 0px"
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Önce Güvenlik
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Güvenliğinize öncelik vererek tüm seansların kontrollü ortamlarda ve büyük bir özenle yapılmasını sağlıyoruz.
						</Text>
					</Box>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12:06:02.422Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-4.jpg?v=2024-06-06T12%3A06%3A02.422Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="center">
						<Icon
							category="ti"
							icon={TiWavesOutline}
							margin="0px 0px 16px 0px"
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							En Son Teknolojiye Sahip Ekipman
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Board'lardan wetsuit'lere kadar her seviyedeki deneyime uygun olarak tasarlanmış yüksek kaliteli ekipmanlarımızı kullanın.
						</Text>
					</Box>
				</Box>
			</Box>
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Yaşam tarzınıza uyum sağlamak için hafta boyunca çeşitli zamanlarda dersler sunuyoruz.
			</Text>
		</Section>
		<Section padding="0 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 1rem 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					width="45%"
					lg-width="100%"
					lg-text-align="center"
				>
					Hakkımızda
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 1rem 0px" color="--darkL2" font="--lead" md-text-align="center">
						OceanWave Surf Academy'de sörf konusunda tutkuluyuz. Misyonumuz, öğrencilerin sörf sanatını öğrenip uygulayabilecekleri destekleyici, eğlenceli ve eğitici bir ortam sağlamaktır. Deneyimli eğitmenlerimiz ve son teknoloji ekipmanlarla her öğrencinin sadece dalgaları değil aynı zamanda güven ve beceriyi de yakalamasını sağlıyoruz.
					</Text>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="40% 0px 0px 0px"
						border-radius="24px"
						md-margin="0px 0px 20px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12:06:02.397Z"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							object-position="center"
							srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/1-3.jpg?v=2024-06-06T12%3A06%3A02.397Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="40% 0px 0px 0px"
						border-radius="24px"
						margin="0px 0px 40px 0px"
						md-margin="0px 0px 20px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12:06:02.435Z"
							width="100%"
							object-fit="cover"
							position="absolute"
							top={0}
							left={0}
							bottom={0}
							right={0}
							max-height="100%"
							srcSet="https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66617501fdcae00021e2f5d9/images/2-5.jpg?v=2024-06-06T12%3A06%3A02.435Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						OceanWave Surf Academy olarak okyanuslarımızı korumanın, onlardan keyif almak kadar önemli olduğuna inanıyoruz. Çevre yönetimine olan bağlılığımız operasyonlarımızın her aşamasına işlenmiştir.
OceanWave'i seçerek sadece sörf yapmayı öğrenmiyorsunuz; gezegenimizin güzel okyanuslarının sağlığına ve sürdürülebilirliğine adanmış bir harekete katılıyorsunuz.
					</Text>
					<Button
						type="link"
						href="/services"
						text-align="center"
						text-decoration-line="initial"
						margin="2rem 0px 0px 0px"
					>
						Hizmetlerimiz
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65d5d38ffdcae00021def28a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});